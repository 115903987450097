import '../css/app.scss';

const $ = require('jquery');

document.addEventListener("DOMContentLoaded", function() {
    $('form').submit(function() {
        $('.loader').removeClass('hide');
    });

    //Handle navigation mobile
    $('.navigation-mobile .burger').click(function(){
        if($(this).hasClass('burger-mobile--open')){
            $('body').removeClass('menu-burger-open');
            $(this).removeClass('burger-mobile--open');
            $('#ml-menu').removeClass('menu--open');
        }
        else{
            $('body').addClass('menu-burger-open');
            $(this).addClass('burger-mobile--open');
            $('#ml-menu').addClass('menu--open');
        }
    });

    $('input').change(function(evt) {
       checkForm();
    });

    $('input').keyup(function(evt) {
        checkForm();
    });

    if($('.global-error').length > 0) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#payment_validate").offset().top
        }, 500);
    };

})

function checkForm() {
    if($('form')[0].checkValidity() == true) {
        $('#payment_validate').prop('disabled',false);
    }
    else {
        $('#payment_validate').prop('disabled',true);
    }
}